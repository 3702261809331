.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
}

.pageContainerInner {
    flex: 1;
    max-width: 800px;
    margin: 0 auto;
}
