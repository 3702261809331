.list {
    background: none;
    position: relative;
    min-height: 100%;
    margin-top: 0 !important;
}

.item {
    position: absolute;
    width: 100%;
    animation: slide-up 400ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.item ion-item {
    border-radius: 10px;
}

.item ion-label {
    position: relative;
    display: flex;
}

@media (prefers-color-scheme: dark) {
    box-shadow: rgba(128, 128, 128, 0.8) 0 2px 2px 0;
}

@keyframes pulse {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .4;
    }
}

