.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



@media (prefers-color-scheme: dark) {
    .light-only {
        display: none;
    }
}

@media (prefers-color-scheme: light) {
    .dark-only {
        display: none;
    }
}