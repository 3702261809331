.instructionsList {
    list-style-type: decimal;
    text-align: left;
}


.instructionsList li {
    color: var(--ion-text-color-secondary);
}

.instructionsList li div {
    min-height: 67px;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    height: 100%;
}

.loadLink {
    all: unset;
    padding-left: 1ch;
    color: var(--ion-color-primary);
}

.loadLink:hover {
    text-decoration: underline;
}