.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
    .container {
        padding-inline-end: var(--padding-end);
    }
}


.newOptionContainer {
    --offset-bottom: 51px;

    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: calc(var(--padding-bottom) + var(--keyboard-offset) + var(--offset-bottom));
    bottom: 0;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}



.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pageContainerInner {
    flex: 1;
}


@media only screen and (min-width: 768px) {
    .pageContainer, .newOptionContainerInner, .buttonContainer, .toolbarContainer {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .newOptionContainer {
        padding-top: 1rem;
    }
}

.buttonContainer {
    padding: 0.5rem 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
