/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** Radix colors https://www.radix-ui.com/docs/colors/palette-composition/the-scales **/

    --radix-sand1: #fdfdfc; /** 253, 253, 252 */
    --radix-sand2: #f9f9f8; /** 249, 249, 248 */
    --radix-sand3: #f3f3f2; /** 243, 243, 242 */
    --radix-sand4: #eeeeec; /** 238, 238, 236 */
    --radix-sand5: #e9e9e6; /** 233, 233, 230 */
    --radix-sand6: #e3e3e0; /** 227, 227, 224 */
    --radix-sand7: #dbdbd7; /** 219, 219, 215 */
    --radix-sand8: #c8c7c1; /** 200, 199, 193 */
    --radix-sand9: #90908c; /** 144, 144, 140 */
    --radix-sand10: #868682; /** 134, 134, 130 */
    --radix-sand11: #706f6c; /** 112, 111, 108 */
    --radix-sand12: #1b1b18; /** 27, 27, 24 */

    --radix-sand-dark1: #161615; /* 22, 22, 21 */
    --radix-sand-dark2: #1c1c1a; /* 28, 28, 26 */
    --radix-sand-dark3: #232320; /* 35, 35, 32 */
    --radix-sand-dark4: #282826; /* 40, 40, 38 */
    --radix-sand-dark5: #2e2e2b; /* 46, 46, 43 */
    --radix-sand-dark6: #353431; /* 53, 52, 49 */
    --radix-sand-dark7: #3e3e3a; /* 62, 62, 58 */
    --radix-sand-dark8: #51504b; /* 81, 80, 75 */
    --radix-sand-dark9: #717069; /* 113, 112, 105 */
    --radix-sand-dark10: #7f7e77; /* 127, 126, 119 */
    --radix-sand-dark11: #a1a09a; /* 161, 160, 154 */
    --radix-sand-dark12: #ededec; /* 237, 237, 236 */

    --radix-yellow-dark1: #1c1500; /** 28, 21, 0 */
    --radix-yellow-dark2: #221a00; /** 34, 26, 0 */
    --radix-yellow-dark3: #2c2100; /** 44, 33, 0 */
    --radix-yellow-dark4: #352800; /** 53, 40, 0 */
    --radix-yellow-dark5: #3e3000; /** 62, 48, 0 */
    --radix-yellow-dark6: #493c00; /** 73, 60, 0 */
    --radix-yellow-dark7: #594a05; /** 89, 74, 5 */
    --radix-yellow-dark8: #705e00; /** 112, 94, 0 */
    --radix-yellow-dark9: #f5d90a; /** 245, 217, 10 */
    --radix-yellow-dark10: #ffef5c; /** 255, 239, 92 */
    --radix-yellow-dark11: #f0c000; /** 240, 192, 0 */
    --radix-yellow-dark12: #fffad1; /** 255, 250, 209 */

    --radix-yellow1: #fdfdf9; /** 253, 253, 249 */
    --radix-yellow2: #fffce8; /** 255, 252, 232 */
    --radix-yellow3: #fffbd1; /** 255, 251, 209 */
    --radix-yellow4: #fff8bb; /** 255, 248, 187 */
    --radix-yellow5: #fef2a4; /** 254, 242, 164 */
    --radix-yellow6: #f9e68c; /** 249, 230, 140 */
    --radix-yellow7: #efd36c; /** 239, 211, 108 */
    --radix-yellow8: #ebbc00; /** 235, 188, 0 */
    --radix-yellow9: #f5d90a; /** 245, 217, 10 */
    --radix-yellow10: #f7ce00; /** 247, 206, 0 */
    --radix-yellow11: #946800; /** 148, 104, 0 */
    --radix-yellow12: #35290f; /** 53, 41, 15 */

    /** primary **/
    --ion-color-primary: var(--radix-yellow10);
    --ion-color-primary-rgb: 245, 217, 10;
    --ion-color-primary-contrast: var(--radix-sand-dark1);
    --ion-color-primary-contrast-rgb: 22, 22, 21;
    --ion-color-primary-shade: var(--radix-yellow11);
    --ion-color-primary-tint: var(--radix-yellow9);

    /** secondary **/
    --ion-color-secondary: var(--radix-yellow6);
    --ion-color-secondary-rgb: 249, 230, 140;
    --ion-color-secondary-contrast: var(--radix-sand-dark1);
    --ion-color-secondary-contrast-rgb: 22, 22, 21;
    --ion-color-secondary-shade: var(--radix-yellow5);
    --ion-color-secondary-tint: var(--radix-yellow7);

    /** tertiary **/
    --ion-color-tertiary: var(--radix-yellow-dark9);
    --ion-color-tertiary-rgb: 245, 217, 10;
    --ion-color-tertiary-contrast: var(--radix-sand-dark1);
    --ion-color-tertiary-contrast-rgb: 22, 22, 21;
    --ion-color-tertiary-shade: var(--radix-yellow10);
    --ion-color-tertiary-tint: var(--radix-yellow-dark10);

    /** success **/
    --ion-color-success: #30a46c;
    --ion-color-success-rgb: 48, 164, 108;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #008346;
    --ion-color-success-tint: #3cb179;

    /** warning **/
    --ion-color-warning: #ffb224;
    --ion-color-warning-rgb: 249, 115, 22;
    --ion-color-warning-contrast: var(--radix-sand-dark1);
    --ion-color-warning-contrast-rgb: 22, 22, 21;
    --ion-color-warning-shade: #ffcb47;
    --ion-color-warning-tint: #fbbf24;

    /** danger **/
    --ion-color-danger: #e5484d;
    --ion-color-danger-rgb: 229, 72, 77;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #dc3d43;
    --ion-color-danger-tint: #f2555a;

    /** dark **/
    --ion-color-dark: var(--radix-sand-dark2);
    --ion-color-dark-rgb: 28, 28, 26;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: var(--radix-sand-dark1);
    --ion-color-dark-tint: var(--radix-sand-dark3);

    /** medium **/
    --ion-color-medium: var(--radix-sand9);
    --ion-color-medium-rgb: 144, 144, 140;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: var(--radix-sand10);
    --ion-color-medium-tint: var(--radix-sand8);

    /** light **/
    --ion-color-light: var(--radix-sand6);
    --ion-color-light-rgb: 227, 227, 224;
    --ion-color-light-contrast: var(--radix-sand12);
    --ion-color-light-contrast-rgb: 27, 27, 24;
    --ion-color-light-shade: var(--radix-sand7);
    --ion-color-light-tint: var(--radix-sand5);


    --ion-text-color-secondary: var(--radix-sand11);
}

@media (prefers-color-scheme: dark) {
    /*
     * Dark Colors
     * -------------------------------------------
     */
    body {
        /** secondary **/
        --ion-color-secondary: var(--radix-yellow-dark6);
        --ion-color-secondary-rgb: 73, 60, 0;
        --ion-color-secondary-contrast: white;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: var(--radix-yellow-dark7);
        --ion-color-secondary-tint: var(--radix-yellow-dark5);

        /** dark **/
        --ion-color-dark: var(--radix-sand2);
        --ion-color-dark-rgb: 249, 249, 248;
        --ion-color-dark-contrast: var(--radix-sand12);
        --ion-color-dark-contrast-rgb: 27, 27, 24;
        --ion-color-dark-shade: var(--radix-sand3);
        --ion-color-dark-tint: var(--radix-sand1);

        /** medium **/
        --ion-color-medium: var(--radix-sand-dark9);
        --ion-color-medium-rgb: 227, 227, 224;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: var(--radix-sand-dark8);
        --ion-color-medium-tint: var(--radix-sand-dark10);

        /** light **/
        --ion-color-light: var(--radix-sand-dark6);
        --ion-color-light-rgb: 53, 52, 49;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: var(--radix-sand-dark7);
        --ion-color-light-tint: var(--radix-sand-dark5);

        --ion-text-color-secondary: var(--radix-sand-dark11);
    }

    /*
     * iOS Dark Theme
     * -------------------------------------------
     */
    .ios body {
        --ion-background-color: var(--radix-sand-dark1);
        --ion-background-color-rgb: 22, 22, 21;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-color-step-50: #222221;
        --ion-color-step-100: #2d2d2c;
        --ion-color-step-150: #393938;
        --ion-color-step-200: #454544;
        --ion-color-step-250: #505050;
        --ion-color-step-300: #5c5c5b;
        --ion-color-step-350: #686867;
        --ion-color-step-400: #737373;
        --ion-color-step-450: #7f7f7e;
        --ion-color-step-500: #8b8b8a;
        --ion-color-step-550: #969696;
        --ion-color-step-600: #a2a2a1;
        --ion-color-step-650: #adadad;
        --ion-color-step-700: #b9b9b9;
        --ion-color-step-750: #c5c5c5;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dcdcdc;
        --ion-color-step-900: #e8e8e8;
        --ion-color-step-950: #f3f3f3;

        --ion-item-background: var(--radix-sand-dark2);

        --ion-card-background: var(--radix-sand-dark3);

    }

    .ios ion-modal {
        --ion-background-color: var(--radix-sand-dark2);
        --ion-toolbar-background: var(--radix-sand-dark3);
        --ion-toolbar-border-color: var(--radix-sand-dark4);
    }


    /*
     * Material Design Dark Theme
     * -------------------------------------------
     */
    .md body {
        --ion-background-color: var(--radix-sand-dark1);
        --ion-background-color-rgb: 22, 22, 21;

        --ion-text-color: #ffffff;
        --ion-text-color-rgb: 255, 255, 255;

        --ion-border-color: var(--radix-sand-dark7);

        --ion-color-step-50: #222221;
        --ion-color-step-100: #2d2d2c;
        --ion-color-step-150: #393938;
        --ion-color-step-200: #454544;
        --ion-color-step-250: #505050;
        --ion-color-step-300: #5c5c5b;
        --ion-color-step-350: #686867;
        --ion-color-step-400: #737373;
        --ion-color-step-450: #7f7f7e;
        --ion-color-step-500: #8b8b8a;
        --ion-color-step-550: #969696;
        --ion-color-step-600: #a2a2a1;
        --ion-color-step-650: #adadad;
        --ion-color-step-700: #b9b9b9;
        --ion-color-step-750: #c5c5c5;
        --ion-color-step-800: #d0d0d0;
        --ion-color-step-850: #dcdcdc;
        --ion-color-step-900: #e8e8e8;
        --ion-color-step-950: #f3f3f3;


        --ion-item-background: var(--radix-sand-dark2);
        --ion-card-background: var(--radix-sand-dark3);
        --ion-toolbar-background: var(--radix-sand-dark3);
        --ion-tab-bar-background: var(--radix-sand-dark3);
    }
}
